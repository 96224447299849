import React from 'react';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import Grid from '@nubank/nuds-web/components/Grid/Grid';
import Box from '@nubank/nuds-web/components/Box/Box';
import Link from '@nubank/nuds-web/components/Link/Link';

import Image from '@nubank/www-latam-commons/components/Image/Image';
import generateDPRsForCMSImage from '@nubank/www-latam-commons/utils/cms/images/generateDPRsForCMSImage';
// TODO: Adjust to the [Website BR Review Checklist](https://github.com/nubank/www-latam/blob/br-pull-request-checklist/sites/br/docs/pull_request_checklist.md)
function SectionMastercardVariant() {
  const imageSrcSet = generateDPRsForCMSImage([{
    imageMobile: { url: 'https://nubank.com.br/images-cms/1713540653-nu_core_2023_04-1-1-1.jpg' },
    imageTablet: { url: 'https://nubank.com.br/images-cms/1713540653-nu_core_2023_04-1-1-1.jpg' },
    imageDesktop: { url: 'https://nubank.com.br/images-cms/1713540653-nu_core_2023_04-1-1-1.jpg' },
  }]);

  return (
    <Box
      tag="section"
      backgroundColor="white"
      paddingVertical={{ xs: '12x', md: '20x', lg: '32x' }}
    >
      <Grid
        paddingLeft={{ xs: '8x', md: '16x' }}
        paddingRight={{ xs: '8x', md: '16x' }}
        rowGap="12x"
        alignItems={{ lg: 'center' }}
      >
        <Grid.Child
          gridColumn={{ xs: '1 / span 4', md: '1 / span 5', lg: '8 / span 4' }}
        >
          <Typography
            variant="heading2"
            color="black"
            marginBottom="4x"
          >
            Cartão Internacional Nubank Mastercard®
          </Typography>
          <Typography
            marginBottom="5x"
            variant="subtitle1"
          >
            Facilidade e segurança para você em mais de 30 milhões de estabelecimentos, além dos
            <Link
              href="https://blog.nubank.com.br/nubank-gold-platinum-diferenca/"
              typographyProps={{
                variant: 'subtitle1',
                strong: true,
              }}
              marginLeft="5px"
            >
              benefícios Mastercard.
            </Link>
          </Typography>
        </Grid.Child>
        <Grid.Child
          gridColumn={{ xs: '1 / span 4', md: '3 / span 4', lg: '2 / span 5' }}
          gridRow={{ lg: '1' }}
        >
          <Image
            loading="lazy"
            srcSet={imageSrcSet}
            alt="Foto de um homen segurando um cartão nubank"
            Title="Homen segurando um cartão nubank"
          />
        </Grid.Child>
      </Grid>
    </Box>
  );
}

export default React.memo(SectionMastercardVariant);
